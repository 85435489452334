export default {
  componentType: 'card',
  endpoint: '/collection(/:guid)',
  objectName: 'collection',
  url: '/collections/:guid',
  getUrl: data => '/collections/' + data.id,
  title: 'collections',
  titleCreate: 'collections.create',
  getValue: data => data.name,
  tabs: [
    {
      title: 'tab.basis',
      type: 'basis',
      children: [
        {
          title: 'collection',
          icon: 'icons/storage.svg',
          required: true,
          components: [
            {name: 'nobr', fieldName: 'code', label: 'code', placeholder: 'code', style: {width: '20%', marginRight: '20px'}, disableFilled: true},
            {name: 'nobr', fieldName: 'name', label: 'title', placeholder: 'title', style: {width: 'calc(60% - 20px)'}},
            {name: 'nobr', fieldName: 'nameEn', label: 'collections.nameEn', placeholder: 'collections.nameEn', style: {width: '80%'}},
            {name: 'Select', relationship: 'institution', label: 'institution', endpointParams: {'response[type]': 'short'}, style: {width: '80%'}, disableFilled: true},
            {name: 'Select', relationship: 'branch', label: 'branch', endpointParams: {'response[type]': 'short'}, style: {width: '80%'}, disableFilled: true}
          ],
          side: 'left'
        },
        {
          title: 'collections.code-howto',
          icon: 'icons/documents.svg',
          components: [
            {name: 'text', fieldName: 'codeHowto', placeholder: 'collections.code-howto', style: {width: '100%'}}
          ],
          side: 'left'
        },
        {
          title: 'collections.virtual-collection',
          icon: 'icons/issue.svg',
          components: [
            { name: 'Bool', fieldName: 'virtualCollection', label: 'collections.virtual-collection', style: { marginBottom: '15px' } },
            { name: 'keywords', pointer: '/keywords', placeholder: 'individuals.field.keywords.placeholder', style: { width: '100%' } }
          ],
          side: 'left'
        },
        {
          title: 'website-status',
          icon: 'icons/website-icon.svg',
          components: [
            {name: 'Switch', relationship: 'publishedOnWeb', kind: 'published_on_web', style: {float: 'none'}}
          ],
          side: 'right'
        },
        {
          title: 'collections.data',
          icon: 'icons/shelf.svg',
          components: [
            {name: 'nobr', fieldName: 'documentsCountIncludingUnpublished', label: 'collections.documentsCountIncludingUnpublished', placeholder: 'no-data', disabled: true, style: {width: 'calc(50% - 20px)', marginRight: '20px'}},
            {name: 'nobr', fieldName: 'documentsCount', label: 'collections.documentsCount', placeholder: 'no-data', disabled: true, style: {width: '50%'}}
          ],
          side: 'right'
        },
        {
          title: 'collections.comment',
          icon: 'icons/documents.svg',
          components: [
            {name: 'text', fieldName: 'notes', placeholder: 'collections.comment.placeholder', style: {width: '100%'}}
          ],
          side: 'right'
        }
      ]
    },
    {
      title: 'tab.description-ru',
      type: 'description-ru',
      children: [
        {
          title: 'tab.description-ru',
          components: [
             {name: 'text', fieldName: 'descriptionRu', placeholder: 'description.placeholder', style: {width: '80%'}}
          ],
          side: 'both'
        }
      ]
    },
    {
      title: 'tab.description-en',
      type: 'description-en',
      children: [
        {
          title: 'tab.description-en',
          components: [
             {name: 'text', fieldName: 'descriptionEn', placeholder: 'collections.descriptionEn.placeholder', style: {width: '80%'}}
          ],
          side: 'both'
        }
      ]
    },
    {
      title: 'tab.storage-locations',
      type: 'storage-locations',
      children: [
        {
          title: 'storage-locations',
          icon: 'icons/storage.svg',
          components: [
            {name: 'TextList', fieldName: 'storageLocations', disabled: true, placeholder: 'storage-locations', style: {width: '100%'}, listStyle: 'none'}
          ],
          side: 'left'
        }
      ]
    },
    {
      title: 'tab.users',
      type: 'users',
      children: [
        {
          title: 'users',
          icon: 'icons/individual.svg',
          components: [
            {name: 'TextList', fieldName: 'users', disabled: true, placeholder: 'users', style: {width: '100%'}, listStyle: 'none'}
          ],
          side: 'left'
        }
      ]
    },
    {
      title: 'tab.files',
      type: 'files',
      children: [
        {
          title: 'files',
          wrapper: false,
          icon: 'icons/media-archive.svg',
          components: [
            {name: 'Files', relationship: 'files'}
          ],
          side: 'both'
        }
      ]
    }
  ]
}
